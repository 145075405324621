<template>
  <div>
    <overlay-alert
      :show-progress="showProgress"
      :process-alert="processAlert"
    />
    <v-alert
      v-if="!permissionCheck('read-board-list') && !permissionCheck('read-group')"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="authorization-alert"
    >
      You are not authorized to perform this action. Please contact your administrator.
    </v-alert>
    <v-card
      v-if="permissionCheck('read-board-list') || permissionCheck('read-group')"
      shaped
      color="#ECEFF1"
    >
      <br>
      <base-tree-view-without-actions
        :value="treeData"
        class="my-tree-view-style"
      >
        <span slot-scope="{node, path, tree}">
          <input
            id="myCheck"
            type="checkbox"
            :checked="node.$checked"
            @change="onCheckBoxChange(node, tree, path)"
          >
          {{ node.text }}
        </span>
      </base-tree-view-without-actions>
      <v-card-actions>
        <v-col
          cols="12"
          class="text-right"
        >
          <v-btn
            class="mt-2"
            color="#FF3700"
            @click="updateCampaign()"
          >
            Update
          </v-btn>
        </v-col>
      </v-card-actions>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
    <v-btn
      v-if="!permissionCheck('read-board-list') && !permissionCheck('read-group')"
      color="red darken-3"
      @click="$router.push({ name: 'Campaign List' })"
    >
      Back
    </v-btn>
    <v-spacer />
  </div>
</template>
<script>
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import { walkTreeData } from 'he-tree-vue';
  import { getDefaultStructure, addMissingBoards } from 'src/helpers/digi-board-structure-helper';
  import global from 'src/mixins/global';
  import moment from 'moment';
  import OverlayAlert from 'src/views/dashboard/component/OverlayAlert';

  export default {
    name: 'SelectDigiBoards',
    components: {
      'centre-spinner': spinner,
      'overlay-alert': OverlayAlert,
    },
    mixins: [global],
    props: {
      campaignFormDetails: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        selectedDigiBoards: [],
        treeData: [],
        loading: false,
        showProgress: false,
        processAlert: '',
      };
    },
    computed: {
      savedStructure () {
        return this.$store.getters['grouping/getSavedStructure'];
      },
      campaignContent () {
        return this.$store.getters['campaigns/getCampaignContent'];
      },
      digiBoards () {
        return this.$store.getters['digiboards/getDigiBoards'];
      },
      advancedScheduleData () {
        return this.$store.getters['schedule/getScheduleData'];
      },
       campaignFiles () {
        return this.$store.getters['campaigns/getCampaignFiles'];
      },
       bannerFiles () {
        return this.$store.getters['campaigns/getBannerFiles'];
      },
    },
    async mounted () {
      this.loading = true;
      this.permissionCheck('read-group') && await this.fetchDigiBoardSavedStructure();
      this.permissionCheck('read-board-list') && await this.fetchDigiBoards();
      this.showBoardTreeList();
      this.updateExisting();
      this.loading = false;
    },
    methods: {
      async fetchDigiBoardSavedStructure () {
        await this.$store.dispatch('grouping/fetchSavedStructure');
      },
      async fetchDigiBoards () {
        await this.$store.dispatch('digiboards/fetchDigiBoards');
      },
      showBoardTreeList () {
        if (Object.keys(this.savedStructure).length <= 0) {
          const digiBoards = this.digiBoards;
          this.treeData = getDefaultStructure(digiBoards);
          return;
        }
        const treeData = this.savedStructure.group;
        const digiBoards = this.digiBoards;
        this.treeData = addMissingBoards(treeData, digiBoards);
      },
      updateExisting () {
        const existingBoards = [];
        for (const details of this.campaignContent.banners) {
         for (const board of details.boards) {
           existingBoards.push(board);
         }
        }
        walkTreeData(this.treeData, (node) => {
          if (existingBoards.includes(node.id)) {
            node.$checked = true;
          }
        });
      },
      onCheckBoxChange (node, tree, path) {
        tree.toggleCheck(node, path);
        const selectedNodes = [];
        tree.walkTreeData((node) => {
          node.$checked && selectedNodes.push(node);
        });
        this.selectedDigiBoards = selectedNodes;
      },

      async updateDigiBoardLink () {
        if (this.permissionCheck('edit-board') === false) {
          this.showProgress = false;
          this.processAlert = '';
          this.$router.push({ name: 'Campaign List' });
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.$store.dispatch('campaigns/clearCampaignContent');
        const campaignId = this.$route.params.id;
        await this.$store.dispatch('campaigns/fetchCampaignContent', {
          id: campaignId,
        });
        this.processAlert = 'Linking...';
        const finalResult = [];
        walkTreeData(this.treeData, (node) => {
          if (node.$checked && node.id !== undefined) {
            finalResult.push(node.id);
          }
        });
        this.$store.dispatch('publish/addSelectedBoardsForPublish', finalResult);
        const formData = new FormData();
        formData.append('_method', 'PUT');
        for (const digiBoard of finalResult) {
          formData.append('digi_boards[]', digiBoard);
        }
        for (const banner of this.campaignContent.banners) {
          await this.$store.dispatch('campaigns/updateCampaignToBoardLink', {
            banner_id: banner.id,
            digi_boards: formData,
          });
        }
        this.processAlert = '';
        this.showProgress = false;
        this.$store.dispatch('alert/onAlert', {
          message: 'Campaign Updated successfully.',
          type: Constants.ALERT_TYPE_SUCCESS,
        });
        this.$store.dispatch('campaigns/clearBannerFile');
        this.$store.dispatch('campaigns/clearCampaignFile');
        this.$router.push({ name: 'Campaign List' });
        this.$store.dispatch('campaigns/clearCampaignContent');
      },
      convertToGmt (date) {
        return moment.utc(date).format();
      },
      updateBanner () {
        const schedules = [];
        for (const advancedSchedule of this.advancedScheduleData) {
          schedules.push(advancedSchedule.id);
        }
        for (const banner of this.campaignContent.banners) {
          this.$store.dispatch('banners/updateBanner', {
            bannerId: banner.id,
            formData: {
              name: this.campaignFormDetails.campaignName,
              start: this.convertToGmt(this.campaignFormDetails.start),
              end: this.campaignFormDetails.end !== null ? this.convertToGmt(this.campaignFormDetails.end) : null,
              rotation_interval: this.campaignFormDetails.rotationIntervals[banner.id] * 1000,
              qr_code_enabled: this.campaignFormDetails.qrCodeEnabledValue[banner.id] !== null ? this.campaignFormDetails.qrCodeEnabledValue[banner.id] : false,
              qr_code_redirect_uri: banner.qr_code_banner !== null ? banner.qr_code_banner.qr_code_redirect_uri : null,
              qr_code_type: banner.qr_code_banner !== null ? banner.qr_code_banner.qr_code_type : null,
              qr_code_x_position: banner.qr_code_banner !== null ? banner.qr_code_banner.qr_code_x_position.toString() : null,
              qr_code_y_position: banner.qr_code_banner !== null ? banner.qr_code_banner.qr_code_y_position.toString() : null,
              schedules: schedules,
            },
          });
        }
      },
      async updateCampaign () {
        this.showProgress = true;
        this.processAlert = 'Updating...';

        for (const campaignFile of this.campaignFiles) {
          if (campaignFile.formData.content_type !== 'image') {
            campaignFile.rotation_interval = parseInt(campaignFile.formData.duration) / 1000;
          }
        }

        const uniqueFileName = [];
        const rotationInterval = [];
        for (const campaignFile of this.campaignFiles) {
          uniqueFileName.push(campaignFile.formData.unique_file_name);
          rotationInterval.push(campaignFile.rotation_interval);
        }

        const scheduleIds = [];
        for (const advancedSchedule of this.advancedScheduleData) {
          scheduleIds.push(advancedSchedule.id);
        }

        for (var i = 0; i < this.campaignFiles.length; i++) {
          await this.$store.dispatch('campaigns/createBannerFiles', {
            unique_file_name: uniqueFileName[i],
            board_listed: JSON.stringify(false),
            name: this.campaignFormDetails.campaignName,
            rotation_interval: rotationInterval[i] * 1000,
            start: this.convertToGmt(this.campaignFormDetails.start),
            end: this.campaignFormDetails.end !== null ? this.convertToGmt(this.campaignFormDetails.end) : null,
            schedules: scheduleIds,
            qr_code_enabled: false,
            qr_code_redirect_uri: null,
            qr_code_type: null,
            qr_code_x_position: null,
            qr_code_y_position: null,
          });
        }

        await this.updateBanner();

        const bannerIds = [];
        for (const result of this.campaignContent.banners) {
          bannerIds.push(result.id);
        }
        for (const banner of this.bannerFiles) {
          bannerIds.push(banner.id);
        }

        await this.$store.dispatch('campaigns/updateCampaign', {
          name: this.campaignFormDetails.campaignName,
          comment: this.campaignFormDetails.comment,
          start: this.convertToGmt(this.campaignFormDetails.start),
          end: this.campaignFormDetails.end !== null ? this.convertToGmt(this.campaignFormDetails.end) : null,
          global: this.campaignFormDetails.global,
          banners: bannerIds,
          schedules: scheduleIds,
          campaignId: this.campaignContent.id,
        }).then(response => {
          this.$store.dispatch('schedule/clearAdvancedSchedule');
          this.$store.dispatch('schedule/clearAdvancedScheduleForEdit');
          this.updateDigiBoardLink();
        }).catch(() => {
          this.$router.push({ name: 'Campaign List' });
        });
      },
    },
  };
</script>
<style scoped>
.my-tree-view-style {
  margin-left: 30px;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
</style>
